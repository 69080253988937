<template>
  <v-container fill-height fluid grid-list-xl pb-5 mb-5>
    <v-layout column white wrap ma-2>
      <!-- CONNECTED USER'S CUSTOMER'S PROJECTS -->
      <v-flex pt-2 pb-0 px-4 d-inline-flex>
        <div>
          <v-btn flat color="primary">
            <span class="text-truncate" style="max-width:12rem">
              {{ project.name }}
            </span>
          </v-btn>
        </div>
      </v-flex>
      <v-divider />
      <v-flex px-4>
        <h2>
          {{ project.name }}
        </h2>
      </v-flex>
      <v-flex>
        <v-flex right py-0>
          <v-btn v-if="isAdmin" center class="success" @click="addSprint">
            <v-icon>mdi-plus-circle-outline</v-icon>
            <v-flex ml-2 pa-0>
              Ajouter un sprint
            </v-flex>
          </v-btn>
        </v-flex>
        <v-flex left py-0>
          <v-btn center class="quaternary" @click="goBack">
            <v-icon>mdi-arrow-left</v-icon>
            <v-flex ml-2 pa-0>
              Retour au dashboard
            </v-flex>
          </v-btn>
        </v-flex>
      </v-flex>
      <material-DataTableSprint
        :items="sprints"
        :loading="loading"
        can-search
        @click:row="sprintDetails"
        @click:edit="editSprint"
        @click:delete="deleteSprint"
      />
      <!-- <v-flex>
        <v-card>
          <v-card-title>
            <h5>
            <v-icon color="info">
              mdi-help-circle-outline
            </v-icon>
            Aides
          </h5>
          </v-card-title>
          <v-flex>
            <p></p>
          </v-flex>
        </v-card>
      </v-flex> -->

      <ui-DialogBox
        title="Suppression d'un sprint"
        msg="Voulez-vous vraiment supprimer le sprint ?"
        :show="sprintToDelete > 0"
        icon-title="mdi-delete-outline"
        cancel-icon="close-circle-outline"
        ok-icon="check-circle-outline"
        @cancel="sprintToDelete = null"
        @ok="confirmDeleteSprint(sprintToDelete)"
      />

      <!-- SNACKBAR -->
      <ui-SnackBarV2
        :text="text"
        :color="color"
        :bottom="bottom"
        :top="top"
        :right="right"
        :left="left"
        :snackbar="snackbar"
      />
    </v-layout>
  </v-container>
</template>
<script>
import RoleMixin from '@/mixins/RoleMixin';
import SnackBarMixin from '@/mixins/SnackBarMixin';

export default {
  name: 'ProjectDetails',

  mixins: [RoleMixin, SnackBarMixin],

  data: () => ({
    projectId: null,
    loading: false,
    sprintToDelete: null,
  }),

  computed: {
    project() {
      return this.$store.getters['project/getProject']() || null;
    },

    sprints() {
      return this.$store.getters['sprint/getProjectSprints']() || [];
    },
  },

  created() {
    this.projectId = this.$route.params.id;
  },

  mounted() {
    // get the project and the sprints associate
    this.$store.commit('app/showLoader');
    Promise.all([
      this.$store.dispatch('project/get_project_by_id', this.projectId),
      this.$store.dispatch('sprint/get_project_sprints', this.projectId),
    ])
      .then((response) => {
        console.log('Project OK', response[0]);
        console.log('Project Sprints OK', response[1]);
      })
      .catch((error) => {
        console.log('Erreur', error);
        this.snack({
          position: 'bottom',
          align: 'center',
          color: 'error',
          text:
            'Impossible de récupérer les sprints du projet : ' + error.message,
        });
      })
      .finally(() => {
        this.$store.commit('app/showLoader');
        this.$store.commit('app/resetDashboardIds');
        this.$store.commit('app/setDashboardIds', {
          item: 'project',
          id: this.project.id,
        });
      });
  },

  methods: {
    goBack() {
      this.$router.push({ path: '/dashboard' });
    },

    sprintDetails(id) {
      console.log('sprintDetails', id);
      this.$router.push({ path: `/sprint/${id}` });
    },

    addSprint() {
      console.log('addSprint');
      this.$router.push({ path: `/sprint/create` });
    },

    editSprint(id) {
      console.log('editSprint', id);
      this.$router.push({ path: `/sprint/${id}/edit` });
    },

    deleteSprint(id) {
      console.log('deleteSprint', id);
      this.sprintToDelete = id;
    },
    confirmDeleteSprint(id) {
      console.log('confirmDeleteSprint', id);
      this.$store
        .dispatch('sprint/delete_sprint_by_id', id)
        .then(() => {
          this.snack({
            position: 'bottom',
            align: 'center',
            color: 'success',
            text: 'Sprint supprimé avec succès',
          });
        })
        .catch((error) => {
          this.snack({
            position: 'bottom',
            align: 'center',
            color: 'error',
            text: 'Impossible de supprimer le sprint : ' + error.message,
          });
        })
        .finally(() => {
          this.sprintToDelete = null;
        });
    },
  },
};
</script>
